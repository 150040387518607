
export default {
  name: "card-acceso-directo",
  props: {
    texto: {
      type: String,
      required: true,
    },
    icono: {
      type: String,
      required: true,
    },
    cardClases: {
      type: String,
      default: "",
    },
  },
};
