
import CardInformacion from "./CardInformacion.vue";
import BarraInformacionOportunidades from "./BarraInformacionOportunidades.vue";
export default {
  name: "card-oportunidades",
  components: {
    "card-informacion": CardInformacion,
    "barra-informacion-oportunidades": BarraInformacionOportunidades,
  },
  props: {
    oportunidades: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
