
import CardInformacion from "./CardInformacion.vue";
export default {
  name: "card-usuarios",
  components: {
    "card-informacion": CardInformacion,
  },
  props: {
    usuarios: {
      type: Object,
      required: true,
    },
  },
  computed: {
    usuariosPorcentaje() {
      if (!this.usuarios.activos) return 0;
      return (this.usuarios.activos * 100) / this.usuarios.disponibles;
    },
  },
  data() {
    return {};
  },
  methods: {},
};
