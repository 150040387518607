
import CardInformacion from "./CardInformacion.vue";
import SeguimientoService from "@/Services/SeguimientoService";
import CompletarSeguimientoModal from "@/views/seguimientos/componentes/CompletarSeguimientoModal.vue";
export default {
  name: "card-seguimientos",
  props: {
    seguimientos: {
      type: Array,
      required: true,
    },
  },
  components: {
    CardInformacion,
    CompletarSeguimientoModal,
  },
  data() {
    return {
      columnasSeguimientos: [
        {
          name: "alerta",
          label: "",
          align: "center",
          style: "width: 100px",
        },
        {
          label: "comentario",
          name: "comentario",
          field: "comentario",
          align: "left",
          style: "width: 30%",
        },
        {
          label: "Fecha",
          field: "recordatorio_fecha",
          align: "left",
          style: "width: 30%",
          format: (val) => this.formatearFecha(val, 2, true),
        },
        {
          name: "opc",
          label: "",
          align: "left",
          style: "width: 10%",
        },
      ],
      modalCompletarSeguimiento: false,
      seguimientoObj: {},
    };
  },
  methods: {
    marcarSeguimientoVencido(seguimiento) {
      return this.dayjs(seguimiento.recordatorio_fecha).isBefore(
        this.dayjs().format("DD/MMMM/YYYY HH:mm:ss")
      );
    },
    async actualizarStatusSeguimiento(seguimiento, statusNuevo) {
      this.loader(true);
      let datos = {
        statusNuevo: statusNuevo,
      };
      await SeguimientoService.actualizarStatus(
        datos,
        seguimiento.oportunidad_seguimiento_id
      )
        .then(async (resp) => {
          this.mostrarAlerta(resp.mensaje, "exito");
          this.loader(false);
          this.$emit("actualizar");
        })
        .catch((error) => {
          this.loader(false);
          this.mostrarAlerta(error, "error");
        });
    },
    manejarActualizacionStatus(seguimiento, statusNuevo) {
      if (statusNuevo == this.constantesStatusCadena.seguimientoCompletado) {
        this.seguimientoObj = seguimiento;
        this.modalCompletarSeguimiento = true;
        return "";
      }
      return this.actualizarStatusSeguimiento(seguimiento, statusNuevo);
    },
    cerrarModalCompletarSeguimiento() {
      this.seguimientoObj = {};
      this.modalCompletarSeguimiento = false;
    },
    manejarExitoCompletar(mensaje) {
      this.cerrarModalCompletarSeguimiento();
      this.mostrarAlerta(mensaje, "exito");
      this.$emit("actualizar");
    },
  },
};
