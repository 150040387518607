
import CardInformacion from "./CardInformacion.vue";
import BarraInformacionIntegraciones from "./BarraInformacionIntegraciones.vue";
export default {
  name: "card-integraciones",
  components: {
    "card-informacion": CardInformacion,
    "barra-informacion-integraciones": BarraInformacionIntegraciones,
  },
  props: {
    integraciones: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
