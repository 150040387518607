
export default {
  name: "card-informacion",
  props: {
    titulo: {
      type: String,
      required: true,
    },
    cardClases: {
      type: String,
      default: "",
    },
    mostrarLink: {
      type: Boolean,
      default: false,
    },
    linkRuta: {
      type: String,
      default: "",
    },
    linkId: {
      type: Number,
      default: 0,
    },
    linkTexto: {
      type: String,
      default: "",
    },
  },
};
