
export default {
  name: "barra-informacion-integraciones",
  props: {
    nombre: {
      type: String,
      required: true,
    },
    icono: {
      type: String,
      required: true,
    },
    integracionInstalada: {
      type: Boolean,
      default: null,
    },
  },
};
