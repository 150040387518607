
import HomeService from "@/Services/HomeService";
import PlataformaService from "@/Services/PlataformaService";
import OportunidadService from "@/Services/OportunidadService";
import CardAccesoDirecto from "./componentes/CardAccesoDirecto.vue";
import CardInformacion from "./componentes/CardInformacion.vue";
import CardSeguimientos from "./componentes/CardSeguimientos.vue";
import CardOportunidades from "./componentes/CardOportunidades.vue";
import CardIntegraciones from "./componentes/CardIntegraciones.vue";
import CardUsuarios from "./componentes/CardUsuarios.vue";
import CampaniaAgregarModal from "../configuraciones/campanias/componentes/CampaniaAgregarModal.vue";
import OportunidadAgregarModal from "../oportunidades/componentes/OportunidadAgregarModal.vue";
import ContactoAgregarModal from "../leads/components/ContactoAgregarModal.vue";
export default {
  name: "home-view",
  components: {
    "card-acceso-directo": CardAccesoDirecto,
    "card-informacion": CardInformacion,
    "card-seguimientos": CardSeguimientos,
    "card-oportunidades": CardOportunidades,
    "card-integraciones": CardIntegraciones,
    "card-usuarios": CardUsuarios,
    CampaniaAgregarModal,
    OportunidadAgregarModal,
    ContactoAgregarModal,
  },
  data() {
    return {
      // now: dayjs().locale("es").format("DD/MMMM/YYYY HH:mm:ss"),
      nombreUsuario: "",
      nombreCliente: "",
      seguimientos: [],
      oportunidades: [],
      integraciones: [],
      usuariosObj: {},
      modalAgregarCampania: false,
      plataformaOpciones: [],
      modalAgregarOportunidad: false,
      agenteOpciones: [],
      modalAgregarContacto: false,
    };
  },
  mounted() {
    this.cargaInicial();
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      this.nombreUsuario = localStorage.getItem("nombreCorto");
      this.nombreCliente = localStorage.getItem("clienteNombreComercial");
      await this.obtenerDetalle(false);
      this.loader(false);
    },
    async obtenerDetalle(loader = true) {
      if (loader) this.loader(true);
      let filtros = {
        usuarioId: localStorage.getItem("autorId"),
      };
      await HomeService.obtenerDetalle(filtros)
        .then((resp) => {
          this.seguimientos = resp.seguimientos;
          this.oportunidades = resp.oportunidades;
          this.usuariosObj = { disponibles: 20, activos: 6 };
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerPlataformas(loader = true) {
      if (loader) this.loader(true);
      let filtros = {
        status: [200],
        ordenar: "titulo_asc",
      };
      await PlataformaService.listadoBasico(filtros)
        .then((resp) => {
          this.plataformaOpciones = resp;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerConcentradoOpcionesOportunidad(loader = true) {
      if (loader) this.loader(true);
      await OportunidadService.obtenerConcentradoOpciones({})
        .then((resp) => {
          this.agenteOpciones = resp.agentes;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    irVistaEmbudoVentas() {
      this.$router.push({ name: "embudoVentasGestor" });
    },
    irVistaSeguimientos() {
      this.$router.push({ name: "seguimientosGestor" });
    },
    irVistaCampanias() {
      this.$router.push({ name: "campaniasGestor" });
    },
    irVistaOportunidadDetalle(oportunidadId) {
      this.$router.push({
        name: "oportunidadesDetalle",
        params: { id: oportunidadId, hash: this.obtenerHash(oportunidadId) },
      });
    },
    irVistaContactoDetalle(leadId) {
      this.$router.push({
        name: "leadsDetalle",
        params: { id: leadId, hash: this.obtenerHash(leadId) },
      });
    },
    async abrirModalAgregarCampania() {
      if (_.isEmpty(this.plataformaOpciones)) await this.obtenerPlataformas();
      this.modalAgregarCampania = true;
    },
    cerrarModalAgregarCampania() {
      this.modalAgregarCampania = false;
    },
    async abrirModalAgregarOportunidad() {
      if (_.isEmpty(this.agenteOpciones)) {
        await this.obtenerConcentradoOpcionesOportunidad();
      }
      this.modalAgregarOportunidad = true;
    },
    cerrarModalAgregarOportunidad() {
      this.modalAgregarOportunidad = false;
    },
    manejarExitoAltaOportunidad(respuesta) {
      this.cerrarModalAgregarOportunidad();
      this.mostrarAlerta(respuesta.mensaje, "exito");
      this.irVistaOportunidadDetalle(respuesta.datos.oportunidadId);
    },
    abrirModalAgregarContacto() {
      this.modalAgregarContacto = true;
    },
    cerrarModalAgregarContacto() {
      this.modalAgregarContacto = false;
    },
    manejarExitoAltaContacto(respuesta) {
      this.cerrarModalAgregarContacto();
      this.mostrarAlerta(respuesta.mensaje, "exito");
      this.irVistaContactoDetalle(respuesta.datos.leadId);
    },
  },
};
