
export default {
  name: "barra-informacion-oportunidades",
  props: {
    titulo: {
      type: String,
      required: true,
    },
    oportunidad: {
      type: Object,
      required: true,
    },
    claseIndicador: {
      type: String,
      required: true,
    },
  },
};
